<template>
  <div class="expand" :style="{height:clientHeight+'px'}">
    <div>
      <el-tree
        ref="expandMenuList"
        class="expand-tree"
        v-if="isLoadingTree"
        :data="setTree"
        node-key="id"
        highlight-current
        :props="defaultProps"
        :expand-on-click-node="false"
        :render-content="renderContent"
        :default-expanded-keys="defaultExpandKeys"
        @node-click="handleNodeClick"
      ></el-tree>
    </div>
  </div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
import Qs from "qs";
import TreeRender from "./tree_render";
import api from "@/resource/api";
import { treeDataTranslate } from "@/utils";
export default {
  name: "tree",
  data() {
    return {
      //         maxexpandId: api.maxexpandId,//新增节点开始id
      //         non_maxexpandId: api.maxexpandId,//新增节点开始id(不更改)
      maxexpandId: "", //新增节点开始id
      non_maxexpandId: "", //新增节点开始id(不更改)
      num: "", //排序
      isLoadingTree: true, //是否加载节点树
      setTree: [], //节点树数据
      clientHeight:
        `${document.documentElement.clientHeight}` - 50 - 30 - 2 - 80,
      defaultProps: {
        children: "children",
        label: "fullName"
      },
      defaultExpandKeys: [], //默认展开节点列表
      currentId: "" //当前点击树id
    };
  },
  mounted() {
    this.initExpand();
    this.getDeptList();
  },
  activated() {},
  methods: {
    initExpand() {
      this.setTree.map(a => {
        this.defaultExpandKeys.push(a.id);
      });
      this.isLoadingTree = true;
    },
    // 获取部门列表
    getDeptList(id) {
      // console.log(data,'dataaaaa');
      // let user = JSON.parse(sessionStorage.getItem("userInfo"))
      // 				let obj = {
      // 					deptId: user.deptId,
      // 					'userId':this.$cookie.get('userId'),
      // 				}
      this.$http({
        url: this.$http.adornUrl("/dict/getSysDicts?type=" + "表单类别"),
        method: "get",
        params: {
          teamsId: JSON.parse(localStorage.currentTeam).id,
          // hospitalId:this.$cookie.get('hospitalId'),
          pageSize: 999,
          pageNo: 1
        }
        // data: Qs.stringify(obj)
      }).then(({ data }) => {
        // if(user.deptId!=null){
        // 	let obj = {
        // 		id:user.deptId,
        // 		fullName:user.deptName,
        // 		simpleName:user.deptName,
        // 		num:'1',
        // 		pids:''
        // 	}
        // 	data.data.unshift(obj)
        // }
        for (var i = 0; i < data.data.length; i++) {
          data.data[i].isEdit = false;
          data.data[i].children = data.data[i].map.list;
        }

        let tree = treeDataTranslate(data.data);
        console.log(tree, "tree");
        const arr1 = [
          {
            name: "问卷分类",
            id: "303868",
            children: []
          }
        ];
        arr1[0].children = tree;
        console.log(arr1, 111);
        this.setTree = arr1;
        this.maxexpandId = data.data[data.data.length - 1].id;
        this.non_maxexpandId = data.data[data.data.length - 1].id;
        this.num = data.data[data.data.length - 1].num;
        this.defaultExpandKeys = id ? [id] : ["303868"];
      });
    },

    handleNodeClick(d, n, s) {
      //点击节点
      d.isEdit = false; //放弃编辑状态
      this.$emit("refreshDataList", d, n);
      this.currentId = d.id;
    },
    renderContent(h, { node, data, store }) {
      //加载节点
      let that = this;
      return h(TreeRender, {
        props: {
          DATA: data,
          NODE: node,
          STORE: store,
          maxexpandId: that.non_maxexpandId,
          currentId: this.currentId || 1
        },
        on: {
          nodeAdd: (s, d, n) => that.handleAdd(s, d, n),
          nodeEdit: (s, d, n) => that.handleEdit(s, d, n),
          nodeDel: (s, d, n) => that.handleDelete(s, d, n)
        }
      });
    },

    handleAdd(s, d, n) {
      //增加节点
      console.log("1");
      // if(n.level >=2){
      //   this.$message.error("最多只支持二级！")
      //   return false;
      // }
      //添加数据
      // let pidStr = ''
      // if(d.pids==''){
      // 	pidStr=d.id
      // }else
      // {
      // 	pidStr=d.pids + ',' + d.id
      // }
      let number = n.childNodes.length + 1;
      let name = d.name + "-" + number;
      let type = n.level == 1 ? "表单类别" : d.name;
      let classify = n.level == 1 ? "表单类别" : d.name;
      console.log(classify, "classify");
      let user = JSON.stringify(localStorage.getItem("userInfo"));
      d.children.push({
        id: "",
        num: ++this.num,
        simpleName: name,
        pid: d.id,
        pids: d.id,
        isEdit: false,
        children: []
      });
      //展开节点
      if (!n.expanded) {
        n.expanded = true;
      }
      let Dept = {
        // 'userId':this.$cookie.get('userId'),
        // 'num': this.num,
        // 'pid': d.id,
        // pids: d.id ,
        // "simpleName": "二级部门",
        // "fullName": "二级部门",
        // "orderWeight": 0,
        status: 1,
        // "version": '',
        teamsId: JSON.parse(localStorage.currentTeam).id,
        type: type,
        name: name,
        createName: user.name,
        classify: "表单类别"
      };
      this.$http({
        url: this.$http.adornUrl("/dict/insert"),
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token")
        },
        data: Qs.stringify(Dept)
      }).then(({ data }) => {
        if (data.status) {
          this.getDeptList(n.data.id);
        }
      });
    },
    handleEdit(s, d, n) {
      //编辑节点
      console.log("触发编辑", s, n);
      d.simpleName = d.fullName;
      console.log(d);
      if (!d.name) {
        this.$message.error("编辑不能为空！");
        this.getDeptList(n.parent.data.id);
        return;
      }
      let obj = {
        id: d.id,
        // 'pid':d.pid,
        // 'pids':d.pids,
        name: d.name
        // 'simpleName':d.simpleName
      };
      // let dept = d
      console.log(obj, "obj");
      this.$http({
        url: this.$http.adornUrl("/dict/update"),
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          // 'Content-Type':' application/json;charset=UTF-8',
          token: this.$cookie.get("token")
        },
        data: Qs.stringify(obj)
      }).then(({ data }) => {
        // console.log(data,'dataaaaa');
        if (data.status) {
          // this.$message({
          // 	message: '123',
          // 	type: "success",
          // 	duration: 1500,
          // 	onClose: () => {

          // 		},
          // });
          this.$message.success(data.msg);
          this.getDeptList(n.parent.data.id);
        }
      });
    },

    handleDelete(s, d, n) {
      //删除节点
      let that = this;
      console.log(d);
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
			}
			let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let type = "";
      let typeSec = "";
      if (d) {
        if (d.type == "表单类别") {
          type = d.value;
          typeSec = "";
        } else {
          type = n.parent.data.value;
          typeSec = d.value;
        }
      }
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/vfTableInfo/list"),
        method: "post",
        params: {
          intParam1: 999,
          intParam2: 1,
          intParam5: typeSec,
          intParam4: type,
					stringParam3: teamsId,
					intParam3: 1,
        }
      }).then(({ data }) => {
        console.log(data, "data");
        if (data.data.length) {
          this.$message.error("此分类下有数据，请清空数据后再删除");
        } else {
          isDel();
        }
      });
      // console.log(this.setTree,'tree');
      // console.log(arr,'arr');
      let delNode = () => {
        let _menu = d.children;
        let menuId = [d.id];
        if (d.children) {
          let len = _menu.length;
          for (let i = 0; i < len; i++) {
            let item = _menu[i];
            if (item.children && item.children.length != 0) {
              let children = item.children;
              for (let j = 0; j < children.length; j++) {
                _menu[len + j] = children[j];
              }
              len = _menu.length;
            }
            menuId.push(item.id);
          }
        }
        let ids = {
          userId: this.$cookie.get("userId"),
          stringParam1: menuId.join(",")
        };
        this.$httpAes({
          url: this.$httpAes.adornUrl("/dict/delete"),
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: this.$cookie.get("token")
          },
          data: ids
        }).then(({ data }) => {
          if (data.status) {
            this.$message.success("删除成功！");
            this.getDeptList(n.parent.data.id);
          }
        });
      };
      //有子级不删除
      // if(d.children && d.children.length !== 0){
      //   this.$message.error("此节点有子级，不可删除！")
      //   return false;
      // }else{
      //新增节点直接删除，否则要询问是否删除
      // let delNode = () => {

      //   let list = n.parent.data.children || n.parent.data,//节点同级数据
      //     _index = 99999;//要删除的index
      //   /*if(!n.parent.data.children){//删除顶级节点，无children
      //     list = n.parent.data
      //   }*/
      //   list.map((c,i) => {
      //     if(d.id == c.id){
      //       _index = i;
      //     }
      //   })
      //   let k = list.splice(_index,1);
      //   //console.log(_index,k)
      //   this.$message.success("删除成功！")
      // }
      let isDel = () => {
        that
          .$confirm("是否删除此节点？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            delNode();
          })
          .catch(() => {
            return false;
          });
      };
      //判断是否新增
      // console.log(d.id > this.non_maxexpandId,'d.id > this.non_maxexpandId');
      //         d.id > this.non_maxexpandId ? delNode() : isDel()
    }
  }
};
</script>

<style>
.expand {
  width: 300px;
  overflow: hidden;
}

.expand > div {
  height: 100%;
  /*  padding-top:20px;
  width:50%;
  margin:20px auto; */
  /* margin-right: 10px; */
  border: 1px solid #cccccc;
  /* max-width: 400px; */
  overflow-y: auto;
}

.expand > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.expand > div::-webkit-scrollbar-thumb {
  background-color: rgba(50, 65, 87, 0.5);
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.expand > div::-webkit-scrollbar {
  width: 10px;
}

.expand-tree {
  border: none;
  margin-top: 10px;
}

.expand-tree .el-tree-node.is-current,
.expand-tree .el-tree-node:hover {
  overflow: hidden;
}

.el-tree-node__content {
  height: 28px;
}

.expand-tree .is-current > .el-tree-node__content .tree-btn,
.expand-tree .el-tree-node__content:hover .tree-btn {
  display: inline-block;
}

.expand-tree .is-current > .el-tree-node__content .tree-label {
  white-space: normal;
}
</style>
